.day{
    flex: 1;
    padding: 20px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 35px;
}

.day:first-child{
    margin-left: 0px;
    border: 1px solid #777;
    background-color: #eee;
}

.day:last-child{
    margin-right: 0px;
}

.day img{
    display: block;
    width: 120px;
}
.day .temp{
    font-size: 35px;
}
.day .temp span{
    margin: auto 5px;
}
.day .temp .temp-day{
    color: #222;
}
.day .temp .temp-night{
    color: #aaa;
}