.header{
    display: block;
    background-color: #eee;
    padding: 25px;
}

input{
    padding: 15px;
    font-size: 28px;
    width: 400px;
    border-radius: 5px;
}

span{
    margin-left: 15px;
    font-size: 28px;
}